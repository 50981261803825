import React from 'react'
import { graphql } from 'gatsby'
import CustomerFeedback from '../components/CustomerFeedback/CustomerFeedback'
import ImageInPage from '../wal-common/src/components/Image/ImageInPage/ImageInPage'
import Layout from '../components/Layout/Layout'

const CustomerFeedbackPage = ({
  data: {
    allMarkdownRemark: { edges: feedbacks },
  },
}) => {
  return (
    <Layout cols={true} title="Kundenerfahrungen">

      <ImageInPage
        file="MVA_2757-1.jpg"
      />

      <div>
        {feedbacks.map(({ node: { id, htmlAst, frontmatter, fields } }) => (
          <CustomerFeedback key={id}
                            cf={{ ...frontmatter, ...fields, htmlAst }}/>
        ))}
      </div>
    </Layout>
  )
}

export default CustomerFeedbackPage

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "customer-feedback" } } }
    ) {
      edges {
        node {
          id
          htmlAst
          fields {
            description
          }
          frontmatter {
            name
            jobAge
          }
        }
      }
    }
  }
`
