import React, { Component } from 'react'
import Blockquote from '../../wal-common/src/components/Blockquote/Blockquote'
import HTMLContent from '../../wal-common/src/components/HTMLContent/HTMLContent'
import { FaCaretDown } from 'react-icons/fa'
import Fade from '../../wal-common/src/components/Transitions/Fade/Fade'
import IconButton from '../../wal-common/src/components/Icon/IconButton/IconButton'

class CustomerFeedback extends Component {
  state = {
    expanded: false,
  }

  expand = () => {
    this.setState({
      expanded: true,
    })
  }

  componentDidMount() {
    if (this.props.expanded) {
      this.expand()
    }
  }

  render() {
    const { cf } = this.props
    const { expanded } = this.state

    let caption = cf.name

    if (cf.jobAge) {
      caption = `${caption}, ${cf.jobAge}`
    }

    return (
      <div className="content">
        <Blockquote caption={caption}>
          <HTMLContent html={cf.description} />
          {!expanded && (
            <p>
              <IconButton
                modifier="moreAnimation"
                Icon={FaCaretDown}
                onClick={this.expand}
              />
            </p>
          )}
          {expanded && (
            <Fade in={true}>
              <HTMLContent html={cf.htmlAst}/>
            </Fade>
          )}
        </Blockquote>
      </div>
    )
  }
}

export default CustomerFeedback
